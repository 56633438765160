import { Star } from "@mui/icons-material";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { LayoutContext } from "wcz-layout";

type TabType = "csCZ" | "en" | "time";

export default function TvPage(): JSX.Element {
    const { changeTitle } = useContext(LayoutContext);
    const [timer, setTimer] = useState<number>(0);
    const [selectedTab, setSelectedTab] = useState<TabType>("csCZ");
    const [currentTime, setCurrentTime] = useState<Date>(new Date());

    // Set the title and animate the progress bar from 0 to 100 then reset.
    useEffect(() => {
        changeTitle("Rate canteen!");
        const interval: NodeJS.Timeout = setInterval(
            () => setTimer((prev: number) => (prev >= 100 ? 0 : prev + 0.5)),
            200
        );
        return () => clearInterval(interval);
    }, [changeTitle]);

    // Automatically cycle the view when the timer resets.
    // A ref is used to skip the initial render.
    const initialRender = useRef(true);
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else if (timer === 0) {
            setSelectedTab((prev: TabType) => {
                if (prev === "csCZ") return "en";
                else if (prev === "en") return "time";
                else return "csCZ";
            });
        }
    }, [timer]);

    // Update the current time every second when the "time" view is active.
    useEffect(() => {
        let timeInterval: NodeJS.Timeout | null = null;
        timeInterval = setInterval(() => setCurrentTime(new Date()), 1000);

        return () => {
            if (timeInterval) clearInterval(timeInterval);
        };
    }, []);

    return (
        <Fragment>
            <LinearProgress
                color="primary"
                variant="determinate"
                value={timer}
                sx={{ position: "fixed", top: { xs: 57, sm: 65 }, left: 0, right: 0, height: 10 }}
            />

            {selectedTab === "csCZ" && (
                <Grid container>
                    <Grid item xs={7} textAlign="center" sx={{ mt: 15 }}>
                        <img src="./logo512.png" alt="logo512" style={{ width: 155 }} />
                        <Typography variant="h2" sx={{ mt: 1 }}>
                            Jak vám dnes chutnalo?
                        </Typography>
                        <Typography variant="h4" sx={{ mt: 1 }}>
                            Ohodnoťte dnešní jídlo v aplikaci rate-canteen
                        </Typography>
                        <Typography variant="h4" sx={{ mt: 3 }}>
                            <Star sx={{ color: "gold" }} fontSize="inherit" />
                            <Star sx={{ color: "gold" }} fontSize="inherit" />
                            <Star sx={{ color: "gold" }} fontSize="inherit" />
                            <Star sx={{ color: "gold" }} fontSize="inherit" />
                            <Star sx={{ color: "gold" }} fontSize="inherit" />
                        </Typography>
                        <Typography variant="h4" sx={{ mt: 8 }}>
                            rate-canteen.wistron.eu
                        </Typography>
                    </Grid>
                    <Grid item xs={5} textAlign="center" sx={{ mt: 20 }}>
                        <QRCode value="https://rate-canteen.wistron.eu" size={400} />
                    </Grid>
                </Grid>
            )}

            {selectedTab === "en" && (
                <Grid container>
                    <Grid item xs={7} textAlign="center" sx={{ mt: 15 }}>
                        <img src="./logo512.png" alt="logo512" style={{ width: 155 }} />
                        <Typography variant="h2" sx={{ mt: 1 }}>
                            How was your food?
                        </Typography>
                        <Typography variant="h4" sx={{ mt: 1 }}>
                            Rate today's food in the rate-canteen app
                        </Typography>
                        <Typography variant="h4" sx={{ mt: 3 }}>
                            <Star sx={{ color: "gold" }} fontSize="inherit" />
                            <Star sx={{ color: "gold" }} fontSize="inherit" />
                            <Star sx={{ color: "gold" }} fontSize="inherit" />
                            <Star sx={{ color: "gold" }} fontSize="inherit" />
                            <Star sx={{ color: "gold" }} fontSize="inherit" />
                        </Typography>
                        <Typography variant="h4" sx={{ mt: 8 }}>
                            rate-canteen.wistron.eu
                        </Typography>
                    </Grid>
                    <Grid item xs={5} textAlign="center" sx={{ mt: 20 }}>
                        <QRCode value="https://rate-canteen.wistron.eu" size={400} />
                    </Grid>
                </Grid>
            )}

            {selectedTab === "time" && (
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "80vh" }}
                >
                    <Typography variant="h1" sx={{ fontSize: 250 }}>
                        {currentTime.toLocaleTimeString("en-GB")}
                    </Typography>
                </Grid>
            )}
        </Fragment>
    );
}
