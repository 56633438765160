import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Container, FormControlLabel, Radio, RadioGroup, Rating, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileViewer, MetaInfo, UploadFile } from "wcz-file";
import { LayoutContext, newGuid } from "wcz-layout";
import RatingModel from "../models/Rating";
import { useGetTodayMenu } from "../services/MenuService";
import { useCreateRating } from "../services/RatingService";

export const AddRatingPage: React.FC = () => {
    const { changeTitle, t, i18n, user, snackbar } = useContext(LayoutContext);
    const [menuOpen, setMenuOpen] = useState(true);
    const [rating, setRating] = useState<RatingModel>({ ...{}, id: newGuid() } as RatingModel);
    const navigate = useNavigate();

    useEffect(() => changeTitle(t("AddRating")), [i18n.language]);

    useEffect(() => {
        if (user.name)
            setRating({ ...rating, userId: user.id, userName: user.name });
    }, [user.name]);

    const { data: todayMenu = [] } = useGetTodayMenu();

    const { mutate: create, isLoading: isCreating } = useCreateRating({
        onSuccess: () => {
            snackbar({ message: t("ThankYouYourReviewHelpsUs") });
            navigate("/");
        },
    });

    const handleOnAddRating = useCallback(() => create(rating), [rating]);

    const toggleMenu = useCallback(() => setMenuOpen(!menuOpen), [menuOpen]);

    const handleOnFoodNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setRating({ ...rating, foodName: event.target.value });
        setMenuOpen(false);
    }, [rating]);

    const handleOnTasteOfFoodChange = useCallback((event: React.ChangeEvent<{}>, value: number | null) => setRating({ ...rating, tasteOfFood: value }), [rating]);
    const handleOnPriceChange = useCallback((event: React.ChangeEvent<{}>, value: number | null) => setRating({ ...rating, price: value }), [rating]);
    const handleOnStaffChange = useCallback((event: React.ChangeEvent<{}>, value: number | null) => setRating({ ...rating, staff: value }), [rating]);
    const handleOnCommentChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setRating({ ...rating, comment: event.target.value }), [rating]);

    const handleOnFileUpload = useCallback(() => setRating({ ...rating, hasFile: true }), [rating]);
    const handleOnFileDelete = useCallback((metas: MetaInfo[]) => setRating({ ...rating, hasFile: !!metas.length }), [rating]);

    const isRatingValid = useMemo(() => !!rating.tasteOfFood || !!rating.price || !!rating.staff || !!rating.comment, [rating]);

    return (
        <Container sx={{ my: 2 }}>
            <Accordion expanded={menuOpen} onChange={toggleMenu}>
                <AccordionSummary expandIcon={<ExpandMore />} >
                    <Typography>{rating.foodName ?? t("YourFood")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <RadioGroup onChange={handleOnFoodNameChange}>
                        {todayMenu.map(menu => <FormControlLabel value={menu.foodName} control={<Radio />} label={menu.foodName} sx={{ mb: 1, wordBreak: "break-word" }} key={menu.foodName} />)}
                        <FormControlLabel value="Minutka" control={<Radio />} label={t("Minute")} sx={{ mb: 1 }} />
                        <FormControlLabel value="Snídaně" control={<Radio />} label={t("Breakfast")} />
                    </RadioGroup>
                </AccordionDetails>
            </Accordion>

            <Card>
                <CardContent>
                    <Typography component="legend">{t("HowWasYourFood")}</Typography>
                    <Rating value={rating.tasteOfFood} size="large" onChange={handleOnTasteOfFoodChange} />

                    <Typography component="legend">{t("Price")}</Typography>
                    <Rating value={rating.price} size="large" onChange={handleOnPriceChange} />

                    <Typography component="legend">{t("HowWasTheStaff")}</Typography>
                    <Rating value={rating.staff} size="large" onChange={handleOnStaffChange} />

                    <Typography component="legend" sx={{ mt: 2 }}>{t("Comment")}</Typography>
                    <TextField value={rating.comment} onChange={handleOnCommentChange} multiline fullWidth helperText={`${rating.comment?.length ?? 0}/1000`} />

                    <UploadFile subId={rating.id} sx={{ mt: 2 }} onSuccess={handleOnFileUpload} />
                    <FileViewer subId={rating.id} sx={{ mt: 1 }} onDelete={handleOnFileDelete} />
                </CardContent>

                <Box sx={{ float: "right", m: 1 }}>
                    <Button variant="contained" onClick={handleOnAddRating} disabled={!isRatingValid || isCreating}>{t("Save")}</Button>
                </Box>
            </Card>

            <Box sx={{ float: "right", mt: 1 }}>
                <Typography variant="caption">{user.name ? t("YouArePostingAs", { userName: user.name }) : t("YouArePostingAsAnAnonymousUser")}</Typography>
            </Box>
        </Container>
    );
};